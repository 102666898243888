import "../App.css";
// import logo from "../svg/RmbLogo.png";
import React, { useRef, useState, useEffect } from "react";
import whatsapp from "../svg/whatsapp.png";
import QRCode from "../svg/QRCode.jpeg"
// import RmbAhmedabadLogo from "../svg/RmbAhmedabadLogo.png";
// import Payment from "../svg/rupee.png";
// import { Navigate } from "react-router-dom";

const Visitor = () => {
  const [submitted, setSubmitted] = useState(false);
  const [valid, setValid] = useState(false);
  const [Check, setCheck] = useState(false);
  //const [file, setFile] = useState();
  const [values, setValues] = useState({
    invitedby: "",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    Category: "",
    company: "",
    meetingdate: "01/02/2025",
    title: "",

  });

  const appClick = () => {
    window.open("https://rmbahmedabad.cognisun.net/privacy-policy/");
  };
  const apiUrl = "https://networking.cognisun.net:4015/"; // Local
  const ref = useRef(null);

  const handleInputChange = (event) => {
    event.preventDefault();

    const { name, value } = event.target;
    setValues((values) => ({
      ...values,
      [name]: value,
    }));
  };

  //   const saveFile = (e) => {
  //     console.log(e.target.files, "dddd");
  //     setFile(URL.createObjectURL(e.target.files[0]));
  //   };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      values.invitedby &&
      values.meetingdate &&
      values.firstname &&
      values.lastname &&
      values.phone &&
      values.phone.length == 10 &&
      values.Category &&
      values.company &&
      // values.ref.current.checked &&
      values.title &&
      Check
    ) {
      //  upload();
      setValid(true);
      VisitorDetail();
    }
    setSubmitted(true);
  };

  const memberlistdropdown = () => {
    fetch(`${apiUrl}member/ListAllData`, {
      method: "POST",
      body: JSON.stringify({
        status: "Active",
        CurrentUser: "497e6600-283c-11e9-9cdf-0bfa851d8c6d",
        city: "80D4CD83-7402-40AA-AB24-562726F1F040",
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.data.length > 0) {
          const personNames = data.data.map((person) => person.FullName);
          setValues((values) => ({
            ...values,
            personNames: personNames,
          }));
        } else {
          // Handle case where no meeting dates are returned
        }
      })
      .catch((err) => {
      });
  };

  const getmeetingdropdown = () => {
    fetch(`${apiUrl}score/GetMeetingDateListDropdown`, {
      method: "POST",
      body: JSON.stringify({
        type: "",
        persionid: "497e6600-283c-11e9-9cdf-0bfa851d8c6d",
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.data.length > 0) {
          const meetingDates = data.data.map((meeting) => meeting.meetingdate);
          setValues((values) => ({
            ...values,
            meetingDates: meetingDates,
          }));
        } else {
          // Handle case where no meeting dates are returned
        }
      })
      .catch((err) => {
      });
  };

  const VisitorDetail = () => {
    fetch(`${apiUrl}visitor/saveVisitor`, {
      method: "POST",
      body: JSON.stringify({
        invitedby: values.invitedby,
        meetingdate: values.meetingdate,
        firstname: values.firstname,
        lastname: values.lastname,
        Category: values.Category,
        company: values.company,
        email: values.email,
        phone: values.phone,
        title: values.title,
        visitorid: "",
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((res) => {
        if (res.data) {

          //setValues(res.values);
        } else {
          // Handle case when response data is not available
        }
      })
      .catch((err) => {
      });
  };
  const handleChange = () => {
    setCheck(!Check);
  };

  useEffect(() => {
    getmeetingdropdown();
    memberlistdropdown();
  }, []);

  return (
    <div className="form-container">
      {/* <form className="register-form" onSubmit={handleSubmit}> */}
      {submitted && valid ? (
        <div className="fullscreen">
          <div className="rightvisitor">
            <h3 style={{ color: "white" }}>Chapter Details</h3>
            <div style={{ color: "white" }}>Chapter Name</div>
            <div style={{ color: "white" }}>RMB Ahmedabad</div>
            <div style={{ color: "white", marginTop: "40px" }}>
              Meeting Date
            </div>
            <div style={{ color: "white" }}>{values.meetingdate}</div>
          </div>
          <div className="leftvisitor">
            <h3>
              Hi {values.firstname} {values.lastname}
            </h3>
            <div style={{ marginTop: "10px" }}>
              Your Registration is Successful.
            </div>
            <div style={{ marginTop: "10px" }}>
              Thank you for confirming your visit to the <b>RMB Ahmedabad</b>{" "}
              Chapter meeting scheduled for <b>{values.meetingdate}.</b>
            </div>
            <p style={{ marginTop: "10px", fontSize: "0.9em", color: "#555", display: "flex", alignItems: "center" }}>
                <button
                  className="call"
                  onClick={() => {
                    window.location.href = `//api.whatsapp.com/send?phone=${"+91" + 9586621096
                      }&text=Hello`;
                  }}
                  style={{
                    border: "none",
                    borderRadius: "50%",
                    padding: "5px",
                    cursor: "pointer",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "10px",
                    height: "10px",
                    marginRight: "10px",
                  }}
                >
                  <img
                    src={whatsapp}
                    alt="WhatsApp Icon"
                    style={{ width: "30px", height: "30px", marginRight: "10px", }}
                  />
                </button>
                Once Paid, please share the screenshot of the transaction to treasure - Rtn. Bharat Banerjee (+91 9586621096)
              </p>
            {/* <div style={{ marginTop: "10px" }}>
              For the payment confirnmation please send the screenshot on
              Whatsapp <b>9429666959</b>
            </div> */}
            <div style={{ marginTop: "20px" }}>Sincerely,</div>
            <div>RMB Ahmedabad</div>
          </div>
        </div>
      ) : (
        <>
          <form className="register-form" onSubmit={handleSubmit}>
            <img
              src={'https://networking.cognisun.net:4015/profilepicture/RMBAhmedabadNew.png'}
              // src={"https://networking.cognisun.net:4015/RMB.png"}
              alt="BG"
            ></img>
            <h2 className="visitorheader">Visitor Registration</h2>


            <label className="label">Title*</label>
            <select
              className="form-field" // Applying the same class for styling
              name="title"
              value={values.title}
              onChange={handleInputChange}
            >
              <option value="">Select Title</option>
              <option value="Mr.">Mr</option>
              <option value="Mrs.">Mrs</option>
              <option value="Miss.">Miss</option>
              <option value="Prof.">Prof</option>
              <option value="Dr.">Dr</option>
            </select>
            {submitted && !values.title && (
              <span id="from-error">Please select title</span>
            )}

            <label className="label">First Name*</label>
            {!valid && (
              <input
                class="form-field"
                type="text"
                placeholder="Enter First Name"
                name="firstname"
                value={values.firstname}
                onChange={handleInputChange}
              />
            )}

            {submitted && !values.firstname && (
              <span id="from-error">Please enter a first name</span>
            )}
            <label className="label">Last Name*</label>
            {!valid && (
              <input
                class="form-field"
                type="text"
                placeholder="Enter Last Name"
                name="lastname"
                value={values.lastname}
                onChange={handleInputChange}
              />
            )}
            {submitted && !values.lastname && (
              <span id="from-error">Please enter a last name</span>
            )}

            <label className="label">Business Name*</label>
            {!valid && (
              <input
                class="form-field"
                type="text"
                placeholder="Enter Business Name"
                name="company"
                value={values.company}
                onChange={handleInputChange}
              />
            )}
            {submitted && !values.company && (
              <span id="from-error">Please enter a businessname</span>
            )}

            <label className="label">Business Category*</label>
            {!valid && (
              <input
                class="form-field"
                type="text"
                placeholder="Enter Business Category"
                name="Category"
                value={values.Category}
                onChange={handleInputChange}
              />
            )}
            {submitted && !values.Category && (
              <span id="from-error">Please enter a businesscategory</span>
            )}

            <label className="label">Email</label>
            {!valid && (
              <input
                class="form-field"
                type="email"
                placeholder="Enter Email"
                name="email"
                value={values.email}
                onChange={handleInputChange}
              />
            )}

            <label className="label">Mobile*</label>
            {!valid && (
              <input
                class="form-field"
                type="phone"
                placeholder="Enter Mobile Number"
                name="phone"
                maxLength={10}
                value={values.phone}
                onChange={handleInputChange}
              />
            )}
            {submitted && !values.phone && (
              <span id="from-error">Please enter mobile </span>
            )}
            {submitted && values.phone && values.phone.length !== 10 && (
              <span id="from-error">
                Please enter a valid 10-digit mobile number
              </span>
            )}
            <label className="label">Invited By*</label>
            <select
              className="form-field" // Applying the same class for styling
              name="invitedby"
              value={values.invitedby}
              onChange={handleInputChange}
            >
              <option value="">Select Invited By</option>
              {values.personNames &&
                values.personNames.map((personName, index) => (
                  <option key={index} value={personName}>
                    {personName}
                  </option>
                ))}
            </select>
            {submitted && !values.invitedby && (
              <span id="from-error">Please select invitedby name</span>
            )}
            <label className="label">Meeting Date*</label>
            <select
              className="form-field"
              name="meetingdate"
              disabled={true}
              value={values.meetingdate}
              onChange={handleInputChange}
            >
              <option value="">Select Meeting Date</option>
              {values.meetingDates &&
                values.meetingDates.map((meetingDate, index) => (
                  <option key={index} value={meetingDate}>
                    {meetingDate}
                  </option>
                ))}
            </select>
            {submitted && !values.meetingdate && (
              <span id="from-error">Please select meeting date</span>
            )}
            <div style={{ textAlign: "center" }}>
              <b>Visitor Registration Fee Rs.1000</b>
            </div>
            <div style={{ fontFamily: 'Arial, sans-serif', padding: '10px', borderRadius: '8px', maxWidth: '400px' }}>
              <h2 style={{ color: '#333', marginBottom: '15px', fontSize: '1.3em', textAlign: "center" }}>Bank Details</h2>
              <p><strong>Bank Name:</strong> IDFC Bank</p>
              <p><strong>Account Holder:</strong> Rotary Means Business Ahmedabad</p>
              <p><strong>Account Number:</strong> 10044274841</p>
              <p><strong>IFSC Code:</strong> IDFB0040302</p>
              <div>
                <img src={QRCode} alt="QR Code" style={{ width: "100%", height: "100%" }} />
              </div>
              <p style={{ marginTop: "10px", fontSize: "0.9em", color: "#555", display: "flex", alignItems: "center" }}>
                <button
                  className="call"
                  onClick={() => {
                    window.location.href = `//api.whatsapp.com/send?phone=${"+91" + 9586621096
                      }&text=Hello`;
                  }}
                  style={{
                    border: "none",
                    borderRadius: "50%",
                    padding: "5px",
                    cursor: "pointer",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "10px",
                    height: "10px",
                    marginRight: "10px",
                  }}
                >
                  <img
                    src={whatsapp}
                    alt="WhatsApp Icon"
                    style={{ width: "30px", height: "30px", marginRight: "10px", }}
                  />
                </button>
                Once Paid, please share the screenshot of the transaction to treasure - Rtn. Bharat Banerjee (+91 9586621096)
              </p>
            </div>

            {/* <div className="payment">
              <img
                src={Payment}
                alt="BG"
                style={{ height: "35px", width: "35px" }}
              />
              <a
                href="upi://pay?pa=paytmqr1qvf8f34or&am=1000&cu=INR@paytm&pn=Paytm"
                style={{ fontSize: "25px", marginLeft: "7px" ,color:"blue" }}
              >
                Payment Link
              </a>
            </div> */}

            {/* <p>
              If you are having difficulty while paying with above link,pay on
              this number:<b>7698935671(Preet Jain)</b>
            </p> */}
            {/* <label className="label">Add PaymentScreenshot*:</label>
            <input type="file" onChange={saveFile} />
            <img src={file} style={{ height: "50%", width: "50%" ,marginTop:"7px" }} />
            {submitted && !file && (
              <span id="from-error">Please upload paymentscreenshot </span>
            )} */}
            <div>
              <input type="checkbox"
                id="termsCheckbox"
                checked={Check}
                onChange={handleChange}
              ></input>
              I agree to the
              <span
                style={{ color: "blue", cursor: "pointer" }}
                onClick={appClick}
              >
                {" "}
                Terms & Conditions
              </span>
            </div>
            {!Check && submitted ?
              <span id="from-error">
                Please select Terms & Conditions
              </span> :
              null
            }
            {!valid && (
              <button class="form-field" type="submit">
                Register
              </button>
            )}
          </form>
        </>
      )}
    </div>
  );
};
export default Visitor;
